.hero-wrapper {
  height: calc(100vh - 100px);
  background-image: url('../../assets/images/dotted-map.png');
  background-size: cover;
  background-position: center;

  .mx {
    height: inherit;
    display: flex;
    align-items: center;
  }
  .hero-container {
    .hero-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      max-width: 50%;
      margin: 0 auto;

      @media (max-width: 900px) {
        max-width: 80%;
      }

      @media (max-width: 600px) {
        max-width: 100%;
      }

      h1 {
        font-weight: 700;
        font-size: 64px;
        line-height: 77px;
        letter-spacing: -0.06em;
        color: #fff;
        margin-bottom: 16px;

        span {
          background: linear-gradient(98.86deg, #fea823 -0.11%, #eb7f00 99.89%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
        @media (max-width: 720px) {
          font-size: 52px;
        }
        @media (max-width: 720px) {
          font-size: 48px;
        }
      }

      p {
        margin-bottom: 32px;
        color: #f5f5f5;
        font-size: 20px;
        line-height: 28px;
        font-family: var(--font-light);
        @media (max-width: 1024px) {
          padding: 10px 0;
        }
        @media (max-width: 720px) {
          font-size: 18px;
        }
      }
      .deposit-btn {
        button {
          padding: 18px 40px;
          font-size: 20px;
          @media (max-width: 720px) {
            padding: 12px 22px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

%base-styles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 5px;
  max-width: 700px;
  padding: 2rem;
  width: 100%;
  font-family: 'Oswald';
}
.modal-close-icon {
  color: #fff;
  margin-bottom: 35px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.modal-reserved {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  color: #fff;
  font-size: 16px;
}
.modal-action {
  display: flex;
  gap: 10px;
  color: #fff;
  font-size: 20px;
  input {
    width: 100%;
  }
}
.modal-btn {
  display: flex;
  justify-content: center;
  button {
    width: 45%;
    margin-top: 20px;
  }
}

.modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.modal {
  &_header {
    margin-bottom: 3.2rem;

    h3 {
      font-size: 2.5rem;
    }

    .close {
      cursor: pointer;
    }
  }
}

.fixed-modal {
  @extend %base-styles;
  // max-width: 480px;
  // width: 100%;

  @media (max-width: 576px) {
    max-width: 90%;
  }
}

.transaction_modal {
  @extend %base-styles;
  max-width: 480px;
  width: 100%;
  display: grid;
  place-items: center;

  @media (max-width: 576px) {
    max-width: 90%;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;

    h2 {
      font-size: 2.4rem;
      line-height: initial;
    }

    > div {
      display: grid;
      place-items: center;
    }

    .processing_loader {
      svg {
        animation: loader 2s ease-in-out infinite;

        @keyframes loader {
          from {
            transform: rotate(0);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}

.wallet_modal {
  @extend %base-styles;
  max-width: 480px;
  width: 100%;
  padding: 1.5em;

  @media (max-width: 576px) {
    max-width: 90%;
  }

  .wallet_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5em;
    margin-bottom: 3.2rem;

    &-card {
      width: 160px;
      box-sizing: border-box;
      cursor: pointer;
      background: #eeee;
      border-radius: 5px;
      padding: 2rem 3rem;
      text-align: center;
      filter: 0px 5px 10px #d0d0d0;

      img {
        width: 80px;
        aspect-ratio: 1;
        object-fit: contain;
      }
    }
  }
}

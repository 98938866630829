.footer {
  margin-top: 1em;
  border-top: 0.5px dotted #e5e5e5;
  padding: 30px 0 50px;

  .footer-container {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width: 600px) {
      width: calc(100% - 40px);
    }

    .widget {
      margin-bottom: 50px;
    }

    .site-logo {
      margin-bottom: 50px;
      display: block;
    }

    .socials {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0;

      .social-item {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }

      li {
        svg {
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  .sub-footer {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width: 600px) {
      width: calc(100% - 40px);
    }
  }
}

.btn {
  border: none;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
}
.btn-primary {
  padding: 16px 40px;
  font-size: 14px;
  background-color: var(--primary);
  border-radius: 8px;
  color: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.1);
    transition: 1s;
  }

  &:disabled {
    opacity: 0.7;
    cursor: no-drop;
  }
}
.btn-secondary {
  padding: 8px 32px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 16px;
  border: 1px solid #fea823;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1));
  transition: opacity 200ms linear;
  opacity: 1;

  &:disabled {
    cursor: no-drop;
    opacity: 0.8;
  }
}
.btn-waring {
  padding: 9px 53px;
  font-size: 16px;
  background: linear-gradient(98.86deg, #fea823 -0.11%, #eb7f00 99.89%);
  border-radius: 8px;
  color: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

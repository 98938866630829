.bridge {
  &-container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 50px;

    @media (max-width: 960px) {
      padding: 50px 0px;
    }
  }

  .bridge-header {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
    height: 38px;

    h3 {
      height: 38px;
      line-height: 38px;
      font-size: 24px;
      font-weight: 800;
      color: rgb(255, 255, 255);
      white-space: nowrap;
      text-transform: capitalize;
    }

    #open-settings-dialog-button {
      position: relative;
      width: 100%;
      border: none;
      margin: 0px;
      height: 35px;
      background-color: rgb(64, 68, 79);
      padding: 0.15rem 0.5rem;
      border-radius: 0.5rem;

      svg {
        height: 20px;
        width: 20px;

        path,
        circle {
          stroke: whitesmoke;
        }
      }
    }
  }

  .reminders {
    width: 100%;
    object-fit: contain;
    border-radius: 0.5625rem;
    border: 0.5px solid rgb(43, 49, 79);
    background-color: rgb(43, 49, 79);
    padding: 1rem 1.25rem;
    margin-top: 0.625rem;

    .list {
      margin: 0px;
      padding: 0px;
      font-size: 12px;
      color: rgb(255, 255, 255);

      dt {
        display: flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        align-items: center;
        font-weight: bold;
        line-height: 1.5;
      }
    }

    ul {
      margin: 0px;
      padding: 0px;
      font-size: 12px;
      color: rgb(255, 255, 255);

      li {
        list-style: initial;
        font-weight: 500;
        line-height: 1.83;
        margin-left: 40px;
      }
    }
  }
}

.controls {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 1rem;

  button {
    padding: 1rem;
    width: 100%;
    font-size: medium;
    height: 54px;
    max-width: 320px;
    font-weight: 800;
    text-align: center;
    border-radius: 12px;
    outline: none;
    border: 1px solid transparent;
    text-decoration: none;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    color: white;
    background-color: var(--primary);

    &:disabled {
      background-color: rgb(64, 68, 79);
      color: rgb(221, 221, 221);
      cursor: auto;
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
      opacity: 0.5;
    }
  }
}

.bridge-form {
  display: grid;
  grid-auto-rows: auto;
  row-gap: 8px;
  background-image: url('../../assets/images/dotted-map.png');
  background-size: cover;
  background-position: center;

  &-container {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    border-radius: 1.25rem;
    background-color: rgb(21, 26, 47);
    width: 100%;

    .input_wrapper {
      padding: 1rem;
      width: 100%;
      background-color: rgb(33, 38, 62);
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0.25rem 8px 0px;
      border-radius: 1.25rem;

      .form_header {
        display: flex;
        flex-flow: row nowrap;
        -webkit-box-align: center;
        align-items: center;
        color: rgb(255, 255, 255);
        font-size: 0.75rem;
        line-height: 1rem;
        padding: 0.75rem 5px 0px 0px;

        &_container {
          box-sizing: border-box;
          margin: 0px;
          min-width: 0px;
          width: 100%;
          display: flex;
          padding: 0px;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          justify-content: space-between;
        }
      }

      .form_input_container {
        display: flex;
        flex-flow: row nowrap;
        -webkit-box-align: center;
        align-items: center;
        padding: 0.75rem 0rem;

        @media (max-width: 1180px) {
          flex-wrap: wrap;
          padding: 0px;
        }

        .input {
          color: rgb(255, 255, 255);
          width: 100%;
          position: relative;
          font-weight: 500;
          outline: none;
          border-top: none;
          border-right: none;
          border-left: none;
          border-image: initial;
          flex: 1 1 auto;
          font-size: 44px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0px;
          appearance: textfield;
          height: 70px;
          background: none;
          border-bottom: 0.0625rem solid rgb(92, 103, 125);
          margin-right: 1.875rem;

          @media (max-width: 1180px) {
            width: 100%;
            margin-right: 0px;
            height: 50px;
            font-size: 24px;
          }
        }

        .token-selection-container {
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          align-items: center;
          width: 100%;
          // overflow: hidden;

          @media (max-width: 1180px) {
            margin-top: 10px;
          }

          .open-currency-select-button {
            -webkit-box-align: center;
            align-items: center;
            color: rgb(151, 157, 172);
            font-size: 1rem;
            height: 70px;
            font-family: Manrope;
            width: 220px;
            border: 0.0625rem solid rgb(54, 61, 95);
            border-radius: 0.75rem;
            background-color: rgb(43, 49, 79);
            outline: none;
            cursor: pointer;
            user-select: none;
            padding: 0px 1.25rem;
            position: relative;
            margin: 0px 5px;

            @media (max-width: 1180px) {
              height: 60px;
              width: 100%;
              padding: 0px 0.625rem;
              margin: 0px 5px;
            }

            > span {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              position: relative;
              padding: 0px 1.625rem 0px 51px;
              width: 100%;
              height: 100%;

              .token-logo {
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                width: 46px;
                height: 46px;
                background: rgb(255, 255, 255);
                box-sizing: border-box;
                border-radius: 100%;
                position: absolute;
                left: 0px;

                > img {
                  width: 24px;
                  height: 24px;
                  min-width: 24px;
                  min-height: 24px;
                  max-width: 100%;
                  max-height: 100%;
                  background-color: white;
                  border-radius: 24px;
                }
              }

              > span {
                text-align: left;
                width: 100%;

                @media (max-width: 960px) {
                  font-size: 12px;
                }

                h3 {
                  font-size: 1rem;
                  font-weight: 800;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: normal;
                  color: rgb(255, 255, 255);
                  margin: 0px 0px 2px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;

                  @media (max-width: 960px) {
                    font-size: 14px;
                  }
                }

                p {
                  width: 100%;
                  font-size: 0.75rem;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                  letter-spacing: normal;
                  color: rgb(255, 255, 255);
                  margin: 3px 0px 0px;

                  @media (max-width: 960px) {
                    font-size: 10px;
                  }
                }
              }

              .down-arrow {
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                width: 1.625rem;
                height: 1.625rem;
                background: rgb(33, 38, 62);
                border-radius: 100%;
                position: absolute;
                top: 1.25rem;
                right: 0px;
              }
            }
          }
        }
      }
    }
  }

  .middle-element {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    width: 100%;
    display: flex;
    padding: 0px;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: space-between;
    position: relative;

    div {
      display: flex;
      padding: 0px;
      -webkit-box-align: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

.form-header-title {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 500;
  font-size: 14px;
  color: rgb(195, 197, 203);
}

.pool-share-stats {
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  flex-wrap: wrap;
  border: 0.5px solid rgb(43, 49, 79);
  background-color: rgb(43, 49, 79);
  border-radius: 0.5625rem;
  padding: 8px 16px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  white-space: nowrap;
}

.coming {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;

  .open-currency-select-button {
    margin: 0 !important;
  }
}

// @media (max-width: 576px) {
//   .coming {
//     width: 165px;
//     right: 24px;
//     top: 170px;
//     padding: 0;
//   }
//   .chain {
//     font-size: 10px !important;
//   }
// }

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Gilroy-Medium';
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('./assets/fonts/Comfortaa-Bold.ttf');
}
@font-face {
  font-family: 'Gilroy-Medium';
  src: url('./assets/fonts/Comfortaa-Medium.ttf');
}
@font-face {
  font-family: 'Gilroy-SemiBold';
  src: url('./assets/fonts/Comfortaa-SemiBold.ttf');
}
@font-face {
  font-family: 'Gilroy-Light';
  src: url('./assets/fonts/Comfortaa-Light.ttf');
}

:root {
  --bg: #000616;
  --primary: #ffba00;
  --text-primary: #fff;

  --w3m-accent-color: #ffba00 !important;
  --w3m-background-color: #ffba00 !important;
}

h2 {
  font-size: 40px;
  color: var(--text-primary);
  text-align: center;
  margin: 1.5em;
}
.dot {
  font-size: 1.3em;
  color: #ffba00;
}
.mx {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--white-color);
}

body {
  background-color: var(--bg);
  color: var(--text-primary);
  background-image: url('./assets/images/body-bg.png');
  background-size: 400px;
  background-repeat: repeat;
}

.pad {
  padding: 0 50px;

  @media (max-width: 800px) {
    padding: 0 30px;
  }

  @media (max-width: 600px) {
    padding: 0 20px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
